import React from 'react';
import VendorCentralGuide from '../../assets/gif/VendorCentralGuide.gif';
import Button from 'components/Button';

interface AccountSelectionGuideProps {
  onSelect?: () => void;
  handleNext?: () => void;
}
const AccountSelectionGuide: React.FC<AccountSelectionGuideProps> = ({
  onSelect,
  handleNext,
}) => (
  <div className="flex flex-col items-center justify-center h-full">
    <img
      src={VendorCentralGuide}
      alt="Vendor Central Guide"
      className="xs:w-1/4 sm:w-1/4 md:w-2/5 lg:w-2/3 xl:w-2/3 2xl:w-2/3 max-w-md rounded-md mt-6"
    />
    <div className="text-center w-[70%] mt-5">
      <div className="font-worksans_medium text-[20px]">Select Account</div>
      <div className="font-worksans_regular text-sm mt-2 text-gray_text">
        Click &quot;Select&quot; to open Vendor Central in a separate window. Choose your
        account and close the window.
      </div>
    </div>
    <div className="flex justify-center absolute bottom-6 right-0 left-0 ">
      <Button
        containerStyles="w-72"
        type="submit"
        btnType="primary"
        btnSize="md"
        main="Select"
        onClick={() => {
          onSelect();
          handleNext();
        }}
      />
    </div>
  </div>
);

export default AccountSelectionGuide;
