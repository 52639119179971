import Button from 'components/Button';
import FormSelect from 'components/Form/FormSelect';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';

export interface IPlatformManagementRegionSelectionForm {
  region: ISelectorOption;
}
interface PlatformManagementRegionSelectionFormProps {
  onSubmit: SubmitHandler<IPlatformManagementRegionSelectionForm>;
  handleNext?: () => void;
  defaultValues?: IPlatformManagementRegionSelectionForm;
}
const PlatformManagementRegionSelectionForm: React.FC<
  PlatformManagementRegionSelectionFormProps
> = ({ onSubmit, handleNext, defaultValues }) => {
  const methods = useForm<IPlatformManagementRegionSelectionForm>({ defaultValues });

  const { handleSubmit, watch } = methods;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(value) => {
          handleSubmit(onSubmit)(value);
          handleNext();
        }}
      >
        <div className="flex justify-center">
          <div className="w-[70%] ">
            <FormSelect
              name="region"
              label="REGION"
              placeholder="Select"
              options={[
                {
                  label: 'North America',
                  value: 'com',
                },
                {
                  label: 'Europe & Middle East',
                  value: 'de',
                },
                {
                  label: 'Asia-Pacific',
                  value: 'com.au',
                },
              ]}
            />
          </div>
        </div>

        <div className="flex items-center justify-center mt-10">
          <div className="w-[70%]">
            <div className="text-[12px] text-gray_text">
              Please choose the region of your Amazon Vendor Central account to continue.
              You’ll be redirected to the correct login page based on your selection.
              <ul className="text-[12px] font-worksans_regular mt-2">
                <li>
                  <span className="font-bold">North America : </span>
                  United States, Canada, Mexico, Brazil
                </li>
                <li>
                  <span className="font-bold">Europe & Middle East : </span>
                  UK, Germany, France, Italy, Spain, Netherlands, Poland, Sweden, Belgium,
                  Saudi Arabia, Egypt, Turkey, UAE, India
                </li>
                <li>
                  <span className="font-bold">Asia-Pacific : </span>
                  Japan, Australia, Singapore
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center absolute bottom-6 right-0 left-0 ">
          <Button
            containerStyles="w-72"
            type="submit"
            btnType="primary"
            btnSize="md"
            main="Next"
            disabled={watch('region') === undefined}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default PlatformManagementRegionSelectionForm;
