/* eslint-disable max-lines */
import Button from 'components/Button';
import VirtualizedDataTable from 'components/DataTable/VirtualizedDataTable';
import { APPTypes } from 'Models/IPlatformManagement';
import React from 'react';

import Modal from 'components/Modal';

import AuthenticatedIcon from '../../assets/images/accountOnboarding/Authenticated.svg';

import PlatformManagementRegionSelectionForm from 'containers/VendorCentralSteps/PlatformManagementRegionSelectionForm';
import TextStepper from 'components/TextStepper';
import AccountSelectionGuide from 'containers/VendorCentralSteps/AccountSelectionGuide';
import AuthenticateAccount from 'containers/VendorCentralSteps/AuthenticateAccount';
import ArrowBack from 'assets/svg/ArrowBack';
import usePlatformManagementAccounts from './usePlatformManagementAccounts';

interface PlatformManagementAccountsProps {
  accountType: APPTypes;
  // coming from react.cloneElement
}

const PlatformManagementAccounts: React.FC<PlatformManagementAccountsProps> = ({
  accountType,
}) => {
  const {
    AddButton,
    OnAuthenticateAccount,
    activeStep,
    addedAccounts,
    columns,
    isLoadingAddedAccounts,
    onAddSellerCentralAccount,
    onCancelAddVendorCentralAccount,
    onCloseModal,
    onSelectAccountAfterGuide,
    onSelectedRowsChange,
    onSubmitAccounts,
    vendorCentralReturnData,
    virtuoso,
    dynamicDetails,
    isAccountSelectionModalOpen,
    accountJobStatus,
    isRegionSelectionModalOpen,
    selectedAccounts,
    vendorCentralUrl,
    setActiveStep,
    data,
    addAccountsMutation,
  } = usePlatformManagementAccounts(accountType);
  return (
    <div>
      <div className="flex items-center">
        {addedAccounts?.accounts?.length !== 0 && !isLoadingAddedAccounts && (
          <div className="w-[25%] h-30 ">
            <AddButton />
          </div>
        )}
        ´
        <div
          className={`${
            addedAccounts?.accounts?.length !== 0 && !isLoadingAddedAccounts
              ? 'w-[72%]'
              : ' w-[100%]'
          } :  mt-2 `}
        >
          <VirtualizedDataTable
            ref={virtuoso}
            columns={columns}
            isLoading={isLoadingAddedAccounts}
            emptyStateComponent={
              <div className="flex flex-col items-center justify-center">
                <div className="font-worksans_regular text-[16px] text-gray-500 text-center mb-10">
                  No Accounts added yet, start adding accounts now.
                </div>
                <AddButton />
              </div>
            }
            data={addedAccounts?.accounts || []}
            className="mt-0 h-[23rem]"
          />
        </div>
        <Modal
          isOpen={isAccountSelectionModalOpen}
          containerStyleOrSize="w-[80vw] h-[81vh]  p--0"
          closeModal={onCloseModal}
          title={`Select ${dynamicDetails?.appName} Accounts`}
        >
          <div>
            <VirtualizedDataTable
              ref={virtuoso}
              columns={columns}
              data={data?.accounts}
              className="mt-2  h-[60vh]"
              selectableRows
              onSelectedRowsChange={onSelectedRowsChange}
              isLoading={!accountJobStatus}
            />
            {!!selectedAccounts?.length && (
              <div className="mt-4 float-end">
                <Button
                  containerStyles="w-72"
                  type="submit"
                  btnType="primary"
                  btnSize="md"
                  main={`Add Selected (${selectedAccounts?.length})`}
                  loading={addAccountsMutation.isLoading}
                  onClick={onSubmitAccounts}
                />
              </div>
            )}
          </div>
        </Modal>
        <Modal
          isOpen={isRegionSelectionModalOpen}
          titleStyle="flex justify-center items-center text-[32px] leading-[37.54px] font-worksans_regular font-medium mt-4"
          containerStyleOrSize="w-[47vw] h-[75vh] xl:h-[50vh] xl:w-[35vw] p-0"
          closeModal={onCancelAddVendorCentralAccount}
          extraActions={
            <div className="absolute left-0 top-0 hidden 2xl:pr-[48px] xl:pr-[48px] pl-[15px] pt-[20px] sm:block">
              <button
                type="button"
                onClick={() =>
                  setActiveStep((prev) => {
                    if (prev === 0) return 0;
                    return prev - 1;
                  })
                }
              >
                {activeStep !== 0 && <ArrowBack className="h-7 w-7" aria-hidden="true" />}
              </button>
            </div>
          }
          title="Add Account"
        >
          {vendorCentralReturnData?.account_id ? (
            <div>
              <div className="flex justify-center items-center h-[40vh]">
                <div className="text-center">
                  <img
                    src={AuthenticatedIcon}
                    alt="Amazon Vendor Central"
                    className="mx-auto"
                  />
                  <div className="mt-4 text-[22px] font-sharpsans_semi_bold">
                    Account Authenticated!
                  </div>
                  <div className="text-[14px] text-gray-500 mt-2">{`Account ID: ${vendorCentralReturnData?.account_id}`}</div>
                  <div className="flex justify-center absolute bottom-6 right-0 left-0 ">
                    <Button
                      containerStyles="w-72 mt-10"
                      type="submit"
                      btnType="primary"
                      btnSize="md"
                      main="Done"
                      onClick={onCancelAddVendorCentralAccount}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <TextStepper
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              steps={[
                {
                  title: 'STEP 1',
                  children: (
                    <PlatformManagementRegionSelectionForm
                      defaultValues={{ region: vendorCentralUrl?.region }}
                      onSubmit={onAddSellerCentralAccount}
                    />
                  ),
                },
                {
                  title: 'STEP 2',
                  disabled: !vendorCentralUrl,
                  children: <AccountSelectionGuide onSelect={onSelectAccountAfterGuide} />,
                },
                {
                  title: 'STEP 3',
                  disabled: !vendorCentralUrl,
                  children: <AuthenticateAccount onSelect={OnAuthenticateAccount} />,
                },
              ]}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default PlatformManagementAccounts;
